<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 操作 -->
      <a-toolbar :loading="loading" refresh @refreshQuery="getList" tid="1">
        <div class="table-operations">
          <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['tenant:iot:expenses:add']">
            <a-icon type="plus" />在线绘制流程
          </a-button>
          <a-upload name="file" :customRequest="customUploadRequest">
            <a-button> <a-icon type="upload" /> 上传部署 </a-button>
          </a-upload>
          <!-- <a-button
            type="dashed"
            shape="circle"
            :loading="loading"
            :style="{ float: 'right' }"
            icon="reload"
            @click="getList"
          /> -->
        </div>
      </a-toolbar>
      <!-- 增加修改 -->
      <create-form ref="createForm" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        tid="1"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
      >
        <span slot="suspendState" slot-scope="text, record">
          {{ suspendStateKV[record.suspendState] }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['tenant:iot:expenses:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['tenant:iot:expenses:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['tenant:iot:expenses:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['tenant:iot:expenses:remove']">
            <a-icon type="rollback" />删除
          </a>
          <a-divider type="vertical" v-hasPermi="['tenant:iot:expenses:remove']" />
          <a @click="setCandidateUsers(record)" v-hasPermi="['tenant:iot:expenses:remove']">
            <a-icon type="rollback" />抄送人
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
    <a-modal
      title="保存"
      :visible="setDeploymentNameVisible"
      :z-index="10001"
      @ok="handleSetDeploymentNameOk"
      @cancel="setDeploymentNameVisible = false"
    >
      <a-form-model-item label="流程部署名称">
        <a-input v-model="deploymentName" />
      </a-form-model-item>
      <a-form-model-item label="所属机构">
        <a-tree-select
          v-model="deptId"
          style="width: 100%"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          :tree-data="deptOptions"
          placeholder="请选择"
          :replaceFields="replaceFields"
          tree-default-expand-all
        >
        </a-tree-select>
      </a-form-model-item>
    </a-modal>
    <a-modal
      title="选择抄送人"
      :visible="setCandidateUsersVisible"
      :z-index="10001"
      @ok="handlCandidateUsersOk"
      @cancel="setCandidateUsersVisible = false"
    >
      <a-select v-model="candidateUsers" mode="multiple" class="candidate-users" :filterOption="handleFilterOption">
        <a-select-option v-for="ak in userList" :key="ak.userName" :text="ak.nickName" :value="ak.userName">
          {{ ak.nickName }}
        </a-select-option>
      </a-select>
    </a-modal>
  </page-header-wrapper>
</template>

<script>
import axios from 'axios'
import { TreeSelect } from 'ant-design-vue'
import { treeselect } from '@/api/system/dept'
import { list, remove } from '@/api/approval/processDefinition'
import CreateForm from './modules/CreateForm'
import { listUser } from '@/api/system/user'
import { configCandidateNotifyUsers } from '@/api/iot/approvalProcessForm'
import storage from 'store'
import { ACCESS_TOKEN } from '@/store/mutation-types'
export default {
  name: 'ProcessDesigner',
  components: {
    CreateForm,
    ATreeSelect: TreeSelect
  },
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 是否可摊销字典
      isAmortizeOptions: [],
      // 查询参数
      queryParam: {
        applicantId: null,
        expenseType: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '流程Id',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '流程Key',
          dataIndex: 'key',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '流程定义名称',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '流程部署名称',
        //   dataIndex: 'deploymentName',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '所属机构',
          dataIndex: 'deptName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '部署时间',
          dataIndex: 'deploymentTime',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '资源名称',
        //   dataIndex: 'resourceName',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '版本',
          dataIndex: 'version',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '流程实例状态',
          dataIndex: 'suspendState',
          scopedSlots: { customRender: 'suspendState' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '抄送人',
          dataIndex: 'notifyUsers',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      uploadUrl: process.env.VUE_APP_BASE_API + '/processDefinition/uploadStreamAndDeployment',
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + storage.get(ACCESS_TOKEN)
      },
      suspendStateKV: {
        1: '激活',
        2: '挂起'
      },
      setDeploymentNameVisible: false,
      formData: null,
      deploymentName: '',
      userList: [],
      allUserList: [],
      candidateUsers: [],
      setCandidateUsersVisible: false,
      deptId: '',
      replaceFields: { children: 'children', title: 'label', key: 'id', value: 'id' },
      deptOptions: []
    }
  },
  filters: {},
  created() {
    this.getData()
  },
  computed: {},
  watch: {},
  methods: {
    /** 查询部门下拉树结构 */
    getTreeselect() {
      const query = {}
      treeselect(query).then((response) => {
        this.deptOptions = response.data
      })
    },
    handleFilterOption(inputValue, option) {
      if (option.data.attrs.text.indexOf(inputValue) >= 0) {
        return true
      }
      return false
    },
    getUserList() {
      listUser({}).then((response) => {
        this.userList = response.rows
        this.allUserList = JSON.parse(JSON.stringify(this.userList))
      })
    },
    customUploadRequest(file) {
      this.formData = new FormData()
      this.formData.append('file', file.file)
      this.deploymentName = ''
      this.setDeploymentNameVisible = true
    },
    handleSetDeploymentNameOk() {
      if (!this.deploymentName) {
        this.$warningEx('请输入流程部署名称')
        return
      }

      if (!this.deptId) {
        this.$warningEx('请选择所属机构')
        return
      }
      this.setDeploymentNameVisible = false
      this.formData.append('deploymentName', this.deploymentName)
      this.formData.append('deptId', this.deptId)
      this.uploadFile(this.formData).then((res) => {
        this.getList()
      })
    },
    handlCandidateUsersOk() {
      if (this.candidateUsers.length === 0) {
        this.$warningEx('请选择抄送人')
      } else {
        this.setCandidateUsersVisible = false
        const myQueryParam = {
          deploymentId: this.deploymentId,
          users: this.candidateUsers
        }
        configCandidateNotifyUsers(myQueryParam).then((res) => {
          this.getList()
        })
      }
    },
    setCandidateUsers(record) {
      this.deploymentId = record.deploymentId
      this.candidateUsers = record.userNames ? record.userNames.split(',') : []
      this.setCandidateUsersVisible = true
    },
    // 设置好头部
    uploadFile(parameter) {
      return axios({
        url: this.uploadUrl,
        method: 'post',
        headers: this.headers,
        data: parameter
      })
    },

    // handleUploadChange(info) {
    //   if (info.file.status === 'done') {
    //     console.log('upload done')
    //     this.getList()
    //   }
    // },

    async getData() {
      this.getTreeselect()
      // 由于列表需要字典数据，所以要先获取到字典数据，再获取列表
      this.getList()
      this.getUserList()
    },
    /** 查询费用记录列表 */
    async getList() {
      this.loading = true
      await list(this.queryParam).then((response) => {
        this.list = response.rows
        this.total = response.total
        this.loading = false
      })
    },

    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.deploymentId
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          return remove(ids).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {}
      })
    }
  }
}
</script>
<style scoped>
.total-amount-label {
  font-weight: 800;
  font-size: 18px;
}
.total-amount {
  font-weight: 800;
  font-size: 18px;
}
</style>
<style lang="less">
.table-operations .ant-upload-list {
  display: none;
}
.candidate-users {
  width: 450px;
}

.ant-select-dropdown {
  z-index: 99999999 !important;
}
</style>
