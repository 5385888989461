var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-header-wrapper",
    [
      _c(
        "a-card",
        { attrs: { bordered: false } },
        [
          _c(
            "a-toolbar",
            {
              attrs: { loading: _vm.loading, refresh: "", tid: "1" },
              on: { refreshQuery: _vm.getList },
            },
            [
              _c(
                "div",
                { staticClass: "table-operations" },
                [
                  _c(
                    "a-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["tenant:iot:expenses:add"],
                          expression: "['tenant:iot:expenses:add']",
                        },
                      ],
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.$refs.createForm.handleAdd()
                        },
                      },
                    },
                    [
                      _c("a-icon", { attrs: { type: "plus" } }),
                      _vm._v("在线绘制流程 "),
                    ],
                    1
                  ),
                  _c(
                    "a-upload",
                    {
                      attrs: {
                        name: "file",
                        customRequest: _vm.customUploadRequest,
                      },
                    },
                    [
                      _c(
                        "a-button",
                        [
                          _c("a-icon", { attrs: { type: "upload" } }),
                          _vm._v(" 上传部署 "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c("create-form", { ref: "createForm", on: { ok: _vm.getList } }),
          _c("a-table", {
            attrs: {
              loading: _vm.loading,
              size: _vm.tableSize,
              tid: "1",
              rowKey: "id",
              columns: _vm.columns,
              "data-source": _vm.list,
              pagination: false,
            },
            scopedSlots: _vm._u([
              {
                key: "suspendState",
                fn: function (text, record) {
                  return _c("span", {}, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.suspendStateKV[record.suspendState]) +
                        " "
                    ),
                  ])
                },
              },
              {
                key: "operation",
                fn: function (text, record) {
                  return _c(
                    "span",
                    {},
                    [
                      _c("a-divider", {
                        directives: [
                          {
                            name: "hasPermi",
                            rawName: "v-hasPermi",
                            value: ["tenant:iot:expenses:edit"],
                            expression: "['tenant:iot:expenses:edit']",
                          },
                        ],
                        attrs: { type: "vertical" },
                      }),
                      _c(
                        "a",
                        {
                          directives: [
                            {
                              name: "hasPermi",
                              rawName: "v-hasPermi",
                              value: ["tenant:iot:expenses:edit"],
                              expression: "['tenant:iot:expenses:edit']",
                            },
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.$refs.createForm.handleUpdate(
                                record,
                                undefined
                              )
                            },
                          },
                        },
                        [
                          _c("a-icon", { attrs: { type: "edit" } }),
                          _vm._v("修改 "),
                        ],
                        1
                      ),
                      _c("a-divider", {
                        directives: [
                          {
                            name: "hasPermi",
                            rawName: "v-hasPermi",
                            value: ["tenant:iot:expenses:remove"],
                            expression: "['tenant:iot:expenses:remove']",
                          },
                        ],
                        attrs: { type: "vertical" },
                      }),
                      _c(
                        "a",
                        {
                          directives: [
                            {
                              name: "hasPermi",
                              rawName: "v-hasPermi",
                              value: ["tenant:iot:expenses:remove"],
                              expression: "['tenant:iot:expenses:remove']",
                            },
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.handleDelete(record)
                            },
                          },
                        },
                        [
                          _c("a-icon", { attrs: { type: "rollback" } }),
                          _vm._v("删除 "),
                        ],
                        1
                      ),
                      _c("a-divider", {
                        directives: [
                          {
                            name: "hasPermi",
                            rawName: "v-hasPermi",
                            value: ["tenant:iot:expenses:remove"],
                            expression: "['tenant:iot:expenses:remove']",
                          },
                        ],
                        attrs: { type: "vertical" },
                      }),
                      _c(
                        "a",
                        {
                          directives: [
                            {
                              name: "hasPermi",
                              rawName: "v-hasPermi",
                              value: ["tenant:iot:expenses:remove"],
                              expression: "['tenant:iot:expenses:remove']",
                            },
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.setCandidateUsers(record)
                            },
                          },
                        },
                        [
                          _c("a-icon", { attrs: { type: "rollback" } }),
                          _vm._v("抄送人 "),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
          _c("a-pagination", {
            staticClass: "ant-table-pagination",
            attrs: {
              "show-size-changer": "",
              "show-quick-jumper": "",
              current: _vm.queryParam.pageNum,
              total: _vm.total,
              "page-size": _vm.queryParam.pageSize,
              showTotal: function (total) {
                return "共 " + total + " 条"
              },
            },
            on: {
              showSizeChange: _vm.onShowSizeChange,
              change: _vm.changeSize,
            },
          }),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: "保存",
            visible: _vm.setDeploymentNameVisible,
            "z-index": 10001,
          },
          on: {
            ok: _vm.handleSetDeploymentNameOk,
            cancel: function ($event) {
              _vm.setDeploymentNameVisible = false
            },
          },
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "流程部署名称" } },
            [
              _c("a-input", {
                model: {
                  value: _vm.deploymentName,
                  callback: function ($$v) {
                    _vm.deploymentName = $$v
                  },
                  expression: "deploymentName",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "所属机构" } },
            [
              _c("a-tree-select", {
                staticStyle: { width: "100%" },
                attrs: {
                  "dropdown-style": { maxHeight: "400px", overflow: "auto" },
                  "tree-data": _vm.deptOptions,
                  placeholder: "请选择",
                  replaceFields: _vm.replaceFields,
                  "tree-default-expand-all": "",
                },
                model: {
                  value: _vm.deptId,
                  callback: function ($$v) {
                    _vm.deptId = $$v
                  },
                  expression: "deptId",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: "选择抄送人",
            visible: _vm.setCandidateUsersVisible,
            "z-index": 10001,
          },
          on: {
            ok: _vm.handlCandidateUsersOk,
            cancel: function ($event) {
              _vm.setCandidateUsersVisible = false
            },
          },
        },
        [
          _c(
            "a-select",
            {
              staticClass: "candidate-users",
              attrs: { mode: "multiple", filterOption: _vm.handleFilterOption },
              model: {
                value: _vm.candidateUsers,
                callback: function ($$v) {
                  _vm.candidateUsers = $$v
                },
                expression: "candidateUsers",
              },
            },
            _vm._l(_vm.userList, function (ak) {
              return _c(
                "a-select-option",
                {
                  key: ak.userName,
                  attrs: { text: ak.nickName, value: ak.userName },
                },
                [_vm._v(" " + _vm._s(ak.nickName) + " ")]
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }